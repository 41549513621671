@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

body {
  font-family: sans-serif !important;
}

/* navbar */
.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 20px 0;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 18px 10px 17px 21px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.start-header.scroll-on .navbar-brand img {
  height: 24px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navigation-wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navbar {
  padding: 0;
}
.navbar-brand img {
  height: 40px;
  width: auto;
  display: block;
  /* filter: brightness(10%); */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none;
}
.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after {
  top: 8px;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}
.nav-link {
  color: #212121 !important;
  font-weight: 500;
  transition: all 200ms linear;
}
.nav-item:hover .nav-link {
  color: #57b846 !important;
}
.nav-item.active .nav-link {
  color: #777 !important;
}
.nav-link {
  position: relative;
  padding: 5px 0 !important;
  display: inline-block;
}
.nav-item:after {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #57b846;
  opacity: 0;
  transition: all 200ms linear;
}
.nav-item:hover:after {
  bottom: 0;
  opacity: 1;
}
.nav-item.active:hover:after {
  opacity: 0;
}
.nav-item {
  position: relative;
  transition: all 200ms linear;
}
.inside_div {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  padding: 23px;
  height: 300px;
  margin-top: 18px;
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
}
@media (max-width: 520px) {
  .inside_div {
    overflow-y: scroll;
    padding: 9px;
    padding-top: 29px;
  }
}

/* #Primary style
================================================== */

.bg-light {
  background-color: #fff !important;
  transition: all 200ms linear;
}
.section {
  position: relative;
  width: 100%;
  display: block;
}
.full-height {
  height: 100vh;
}
.over-hide {
  overflow: hidden;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: 40px;
  transform: translateY(-50%);
  z-index: 20;
}
h1 {
  font-size: 48px;
  line-height: 1.2;
  font-weight: 700;
  color: #212112;
  text-align: center;
}
.para {
  text-align: center;
  margin: 0;
  padding-top: 10px;
  opacity: 1;
  transform: translate(0);
  transition: all 300ms linear;
  transition-delay: 1700ms;
  font-size: 16px;
  line-height: 30px;
  color: #212122;
  font-weight: 300;
}
body.hero-anime p {
  opacity: 0;
  transform: translateY(40px);
  transition-delay: 1700ms;
}
h1 span {
  display: inline-block;
  transition: all 300ms linear;
  opacity: 1;
  transform: translate(0);
}
body.hero-anime h1 span:nth-child(1) {
  opacity: 0;
  transform: translateY(-20px);
}
body.hero-anime h1 span:nth-child(2) {
  opacity: 0;
  transform: translateY(-30px);
}
body.hero-anime h1 span:nth-child(3) {
  opacity: 0;
  transform: translateY(-50px);
}
body.hero-anime h1 span:nth-child(4) {
  opacity: 0;
  transform: translateY(-10px);
}
body.hero-anime h1 span:nth-child(5) {
  opacity: 0;
  transform: translateY(-50px);
}
body.hero-anime h1 span:nth-child(6) {
  opacity: 0;
  transform: translateY(-20px);
}
body.hero-anime h1 span:nth-child(7) {
  opacity: 0;
  transform: translateY(-40px);
}
body.hero-anime h1 span:nth-child(8) {
  opacity: 0;
  transform: translateY(-10px);
}
body.hero-anime h1 span:nth-child(9) {
  opacity: 0;
  transform: translateY(-30px);
}
body.hero-anime h1 span:nth-child(10) {
  opacity: 0;
  transform: translateY(-20px);
}
h1 span:nth-child(1) {
  transition-delay: 1000ms;
}
h1 span:nth-child(2) {
  transition-delay: 700ms;
}
h1 span:nth-child(3) {
  transition-delay: 900ms;
}
h1 span:nth-child(4) {
  transition-delay: 800ms;
}
h1 span:nth-child(5) {
  transition-delay: 1000ms;
}
h1 span:nth-child(6) {
  transition-delay: 700ms;
}
h1 span:nth-child(7) {
  transition-delay: 900ms;
}
h1 span:nth-child(8) {
  transition-delay: 800ms;
}
h1 span:nth-child(9) {
  transition-delay: 600ms;
}
h1 span:nth-child(10) {
  transition-delay: 700ms;
}
body.hero-anime h1 span:nth-child(11) {
  opacity: 0;
  transform: translateY(30px);
}
body.hero-anime h1 span:nth-child(12) {
  opacity: 0;
  transform: translateY(50px);
}
body.hero-anime h1 span:nth-child(13) {
  opacity: 0;
  transform: translateY(20px);
}
body.hero-anime h1 span:nth-child(14) {
  opacity: 0;
  transform: translateY(30px);
}
body.hero-anime h1 span:nth-child(15) {
  opacity: 0;
  transform: translateY(50px);
}
h1 span:nth-child(11) {
  transition-delay: 1300ms;
}
h1 span:nth-child(12) {
  transition-delay: 1500ms;
}
h1 span:nth-child(13) {
  transition-delay: 1400ms;
}
h1 span:nth-child(14) {
  transition-delay: 1200ms;
}
h1 span:nth-child(15) {
  transition-delay: 1450ms;
}
#switch,
#circle {
  cursor: pointer;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
#switch {
  width: 60px;
  height: 8px;
  border: 2px solid #8167a9;
  border-radius: 27px;
  background: #000;
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  transform: translate(0);
  transition: all 300ms linear;
  transition-delay: 1900ms;
}
body.hero-anime #switch {
  opacity: 0;
  transform: translateY(40px);
  transition-delay: 1900ms;
}
#circle {
  position: absolute;
  top: -11px;
  left: -13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #000;
}
.switched {
  border-color: #000 !important;
  background: #8167a9 !important;
}
.switched #circle {
  left: 43px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #fff;
}
.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  display: block;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}
.nav-item.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0px, 0);
}
.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}
.dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  padding: 3px 15px;
  color: #212121;
  border-radius: 2px;
  transition: all 200ms linear;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff;
  background-color: rgb(152 197 160 / 87%);
}

body.dark {
  color: #fff;
  background-color: #1f2029;
}
body.dark .navbar-brand img {
  filter: brightness(100%);
}
body.dark h1 {
  color: #fff;
}
body.dark h1 span {
  transition-delay: 0ms !important;
}
body.dark p {
  color: #fff;
  transition-delay: 0ms !important;
}
body.dark .bg-light {
  background-color: #14151a !important;
}
body.dark .start-header {
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
}
body.dark .start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}
body.dark .nav-link {
  color: #fff !important;
}
body.dark .nav-item.active .nav-link {
  color: #999 !important;
}
body.dark .dropdown-menu {
  color: #fff;
  background-color: #1f2029;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
}
body.dark .dropdown-item {
  color: #fff;
}
body.dark .navbar-light .navbar-toggler-icon {
  border-bottom: 1px solid #fff;
}
body.dark .navbar-light .navbar-toggler-icon:after,
body.dark .navbar-light .navbar-toggler-icon:before {
  background-color: #fff;
}
body.dark .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/* #Media
================================================== */

@media (max-width: 767px) {
  h1 {
    font-size: 38px;
  }
  .nav-item:after {
    display: none;
  }
  .nav-item::before {
    position: absolute;
    display: block;
    top: 15px;
    left: 0;
    width: 11px;
    height: 1px;
    content: "";
    border: none;
    background-color: #000;
    vertical-align: 0;
  }
  .dropdown-toggle::after {
    position: absolute;
    display: block;
    top: 10px;
    left: -23px;
    width: 1px;
    height: 11px;
    content: "";
    border: none;
    background-color: #000;
    vertical-align: 0;
    transition: all 200ms linear;
  }
  .dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(90deg);
    opacity: 0;
  }
  .dropdown-menu {
    padding: 0 !important;
    background-color: transparent;
    box-shadow: none;
    transition: all 200ms linear;
  }
  .dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }
  body.dark .nav-item::before {
    background-color: #fff;
  }
  body.dark .dropdown-toggle::after {
    background-color: #fff;
  }
  body.dark .dropdown-menu {
    background-color: transparent;
    box-shadow: none;
  }
}

/* #Link to page
================================================== */

.logo {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: block;
  z-index: 100;
  transition: all 250ms linear;
}
.logo img {
  height: 26px;
  width: auto;
  display: block;
  filter: brightness(10%);
  transition: all 250ms linear;
}
body.dark .logo img {
  filter: brightness(100%);
}

/* login page */

.login_page {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
}

.login_card {
  border-radius: 10px;
  padding: 70px 70px 70px 10px;
}

.login_head {
  font-weight: 800;
  font-size: 30px;
  font-family: emoji;
  color: #101928;
}
.login_form input {
  padding: 25px;
  border-radius: 10px;
}

.form-control {
  border-color: #101928;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #101928 !important;
  outline: 0;
  box-shadow: none !important;
}
.login_form {
  background-color: transparent;
  padding: 7px 25px;
  color: #101928;
  font-weight: 400;
  /* border: 1px solid #6a2a69; */
  border-radius: 7px;
  border: none;
}
.parent {
  background-color: #f7efff;
}
.login_button {
  width: 160px;
  border: none;
  padding: 8px 20px !important;
  background-color: #101928;
  color: #ffffff;
  border-radius: 7px;
  align-items: center;
}
.forgot {
  font-size: 16px;
  color: #101928;
}
.side {
  background-color: #ffffff;
}
.loginCard {
  justify-content: center;
  display: flex;
  height: 100vh;
}
.logincardstyle {
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  align-self: center;
  justify-content: center;
  display: flex;
}

/* signup */
.signUpHead {
  justify-content: space-around;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.reviewLogo {
  width: 100px;
  /* margin-left: 160px; */
}

/* admin home */

.bannerContainer {
  height: 500px;
  background-image: linear-gradient(to right, #aa00ff, #9400b2);
}
.secondBanner {
  background-color: #fcfbf3;
}
.iconuser {
  font-size: 40px;
  color: silver;
  padding: 5px;
}
.iconstar4 {
  color: #00b67a;
  margin-top: 20px;
  font-size: 10px;
  margin: 3px;
}
.reviewcard {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  border-radius: 15px;
}
/* YourComponent.css */

/* YourComponent.css */

.marquee-container {
  display: flex;
  overflow: hidden;
}

.reviewcard {
  animation: none; /* Disable animation by default */

  @media (min-width: 768px) {
    /* Enable animation for medium-sized screens and larger */
    animation: marquee 20s linear infinite; /* Adjust the duration and other properties as needed */
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.registerButton {
  background-color: #57b846;
  color: white;
  width: auto;
  font-weight: 500;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 5px;
  border: none;
}
.seacrhMainContainer {
  background-color: white;
  border-radius: 15px;
  justify-content: space-between;
}
.searchBox {
  border: none;
  outline: none;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 50%;
}
@media (max-width: 768px) {
  .searchBox {
    width: 100%;
  }
}
.indus {
  color: #68009c;
  font-size: 30px;
  font-weight: bold;
}
.sText {
  font-size: 19px;
}

/* scroll */
::-webkit-scrollbar {
  width: 1px;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background: #f9f6f9;
}
::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #fcfbfb;
}
::-webkit-scrollbar-thumb:active {
  background: #f9f8f8;
}

/* footer */

.footerSection {
  background-color: #27182f;
  color: white;
}
/* .firstFooterContainer {
  justify-content: space-between;
} */

.footerSection {
  padding: 20px 0px 60px;
}
.footerText {
  font-size: 11px;
  line-height: 1.5rem;
}
@media (max-width: 768px) {
  .secondFooterContainer {
    display: flex;
    flex-direction: column;
    justify-content: initial;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  #PabBtmLogo {
    width: 100px;
  }
  .footerSection {
    padding: 20px;
  }
  .secondFooterContainer {
    padding: 5px;
    line-height: 3;
  }
}

#linked {
  transition: 5s;
}
#linked:hover {
  transform: rotate(360deg);
}
#insta {
  transition: 5s;
}
#insta:hover {
  transform: rotate(360deg);
}
#fb {
  transition: 5s;
}
#fb:hover {
  transform: rotate(360deg);
}
#twitter {
  transition: 5s;
}
#twitter:hover {
  transform: rotate(360deg);
}
.pabTopLogo {
  width: 200px;
}
/* .secCol,
.triCol,
.forCol {
  font-size: 12px;
  word-spacing: 5px;
  line-height: 1.3rem;
} */
.logo1 {
  color: #ffffff;
  font-size: 25px;
}
.thick-horizontal-line {
  height: 1px;
  background-color: white;
}
/* @import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700); */
/* body {
 font-family:sans-serif;
 position:relative;
 font-weight:400;
 font-size:15px
} */
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
a:focus,
a:hover {
  text-decoration: none;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
a:focus {
  outline: 0;
}
img {
  max-width: 100%;
}

.footerTitle {
  font-family: sans-serif;
  margin: 0;
  font-weight: 700;
  padding: 0;
  color: #ffffff;
}
a {
  color: #5867dd;
}
.no-padding {
  padding: 0 !important;
}
.go_top {
  line-height: 40px;
  cursor: pointer;
  width: 40px;
  background: #5867dd;
  color: #fff;
  position: fixed;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  right: -webkit-calc((100% - 1140px)/ 2);
  right: calc((100% - 1140px) / 2);
  z-index: 111;
  bottom: 80px;
  text-align: center;
  font-size: 25px;
}
.arrow {
  color: #fff;
}
.go_top span {
  display: inline-block;
}
/* .footer-big {
  padding: 105px 0 65px 0;
} */
.footer-big .footer-widget {
  margin-bottom: 40px;
}
.footer--light {
  background: #27182f;
}
.footer-big .footer-menu ul li a,
.footer-big p,
.footer-big ul li {
  color: #fff;
}
.footer-menu {
  padding-left: 48px;
}
.footer-menu ul li a {
  font-size: 15px;
  line-height: 32px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.footer-menu ul li a:hover {
  color: #5867dd;
}
.footer-menu--1 {
  width: 100%;
}
.footer-widget-title {
  line-height: 42px;
  margin-bottom: 10px;
  font-size: 18px;
}
.mini-footer {
  background: #292b2d;
  text-align: center;
  padding: 32px 0;
}
.mini-footer p {
  margin: 0;
  line-height: 26px;
  font-size: 15px;
  color: #999;
}
.mini-footer p a {
  color: #5867dd;
}
.mini-footer p a:hover {
  color: #34bfa3;
}
.widget-about img {
  display: block;
  margin-bottom: 30px;
}
.widget-about p {
  font-weight: 400;
}
.widget-about .contact-details {
  margin: 30px 0 0 0;
}
.widget-about .contact-details li {
  margin-bottom: 10px;
}
.widget-about .contact-details li:last-child {
  margin-bottom: 0;
}
.widget-about .contact-details li span {
  padding-right: 12px;
}
.widget-about .contact-details li a {
  color: #5867dd;
}
@media (max-width: 991px) {
  .footer-menu {
    padding-left: 0;
  }
}
.cardContainer {
  border: none;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
}
.cardheading {
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;
}

.categoryReviewContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 2rem;
}
.icons {
  font-size: 25px;
  margin-left: 5px;
}
.BtnRvw {
  border: none;
  background-color: #f5f5f5;
  color: #161616;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid #ddd;
}
.mainCont {
  border-radius: 15px;
  border: 1px solid #ddd;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
  padding: 20px !important;
}
.staricon {
  font-size: 25px;
  margin-left: 7px;
}
@media (max-width: 576px) {
  .ReviewForm {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      width: 100%;
      margin-bottom: 10px;
    }

    /* button {
      width: 100%;
    } */
  }
}
.gridcontainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 25px;
  background-color: #f5f5f5;
  padding-top: 70px;
}
.ratingContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.star {
  font-size: 20px;
  padding: 2px;
  color: #817f7f;
}

.ratingStars {
  color: #f7a200;
}

.ratingStars-1 .star:nth-child(-n + 1),
.ratingStars-2 .star:nth-child(-n + 2),
.ratingStars-3 .star:nth-child(-n + 3),
.ratingStars-4 .star:nth-child(-n + 4),
.ratingStars-5 .star:nth-child(-n + 5) {
  color: #f7a200;
}

.reviewContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.5rem;
}

.review-card {
  border: none;
  padding: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  text-align: center;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 767px) {
  .container {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1250px;
  }
}

@media (max-width: 1024px) {
  .sideContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .ratingContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

.sideContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  gap: 10px;
}

.ratingContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.parentcont {
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  padding: 11px;
}
.gridcontainer1 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

/* adding company */

* {
  box-sizing: border-box;
}
.contactbutton {
  margin-top: 15px;
}

.addcpmanyContainer {
  margin: 15% auto;
  height: 100%;
  border-radius: 20px;

  background-repeat: no-repeat;
  padding-bottom: 2%;
}

.header {
  text-align: center;
  padding-top: 40px;
}

.header h2 {
  color: #333;
  font-size: 40px;
  margin-bottom: 5px;
}

.main {
  margin-left: 20px;
  margin-right: 20px;
}

.iptag {
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  color: black;
  letter-spacing: 1px;
  padding-left: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: none;
  background: #f1f1f1;
}

/* Set a style for the submit/register button */
.registerbtn {
  background-color: #047baa;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
  border-radius: 10px;
}

.registerbtn:hover {
  opacity: 1;
}

/* Add a blue text color to links */
a {
  color: dodgerblue;
}

/* Set a grey background color and center the text of the "sign in" section */
.signin {
  background-color: #f1f1f1;
  text-align: center;
}
[class*="column-"] {
  width: 100%;
}

@media only screen and (min-width: 568px) {
  .column-12 {
    width: 66.66%;
  }
}
.submitBtn {
  color: #fff;
  background-color: #57b846;
  border: none;
  border-radius: 10px;
  font-weight: 500;
  padding: 5px 10px;
  float: right;
}

.review-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin: 20px;
  background-color: white;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.breadcrumb {
  font-size: 14px;
  color: #666;
}

.breadcrumb span {
  margin-right: 5px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.actions {
  margin-left: 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.mouthshut-score {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.mouthshut-score span {
  font-size: 14px;
  color: #666;
}

.rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.rating span {
  font-size: 16px;
  font-weight: bold;
}

.categories {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.review-categories-grid {
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  /* border: 1px solid #ddd; */
  border-radius: 3px;
  font-size: 14px;
}
.category-item {
  padding: 8px 18px;
  margin-right: 5px;
  margin-bottom: 5px;
  box-shadow: rgba(149, 157, 183, 0.2) 0px 2px 8px;
  border-radius: 3px;
  font-size: 14px;
  color: #696363e0;
}

.write-review {
  text-align: center;
  margin-top: 10px;
}
.rvwBtn {
  padding: 10px 20px;
  border: 1px solid #ddd;
  cursor: pointer;
  color: white;
  font-size: 14px;
  cursor: pointer;
  background: #57b846;
  border-radius: 10px;
}

button:focus {
  outline: none !important;
}

.hcltech-reviews {
  font-family: "Roboto", sans-serif;
  /* margin: 20px; */
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  /* padding: 20px; */
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

.options {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.options button {
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.current-employee {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  margin-bottom: 10px;
}

.reviews {
  margin-top: 20px;
}

.reviews h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.review-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
}

.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.review-header h3 {
  font-size: 16px;
  font-weight: revert;
}

.review-header span {
  font-size: 12px;
  color: #ccc;
}

.review-body {
  line-height: 1.6;
  margin-bottom: 10px;
  width: 100%;
}

.likes-dislikes {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.likes,
.dislikes {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.likes span,
.dislikes span {
  font-size: 14px;
  font-weight: bold;
}

.likes ul,
.dislikes ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.likes li,
.dislikes li {
  font-size: 12px;
  line-height: 1.6;
}

.review-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #ccc;
}

.review-footer span {
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .header h1 {
    font-size: 20px;
  }
  .options button {
    font-size: 14px;
  }
  .current-employee {
    font-size: 10px;
  }
  .reviews h2 {
    font-size: 16px;
  }
  .review-header h3 {
    font-size: 14px;
  }
  .review-body {
    font-size: 12px;
  }
  .likes span,
  .dislikes span {
    font-size: 12px;
  }
  .likes li,
  .dislikes li {
    font-size: 10px;
  }
  .review-footer span {
    font-size: 8px;
    margin-right: 30px;
  }
}
.modalBtn {
  border: none;
  background-color: #34004e;
  color: white;
  font-size: 13px;
  font-weight: 600;
  border-radius: 10px;
  padding: 5px 10px;
}
.modalIp {
  border: none;
  background-color: #f1f1f1;
}
.modalLabel {
  color: #000;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  letter-spacing: inherit;
  color: rgb(56, 54, 54);
  padding: 2px 0 2px !important;
  border: none !important;
  box-sizing: content-box;
  background: #f7f7f7 !important;
  height: 1.5rem !important;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 12px 8px !important;
}
.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: none !important;
  outline: none !important;
  border: none !important;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-right: -12px;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem !important;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  background: #f7f7f7 !important;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 4px;
  padding-right: 10px !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.5em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 12px 8px !important;
  padding-right: 0;
}
.css-1vhaqj4-MuiButtonBase-root-MuiButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: none !important;
  border: none !important;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 4px !important;
  padding: 6px 16px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #68009c !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  margin-top: 24px;
  margin-bottom: 16px;
}

.bannerContainer1 {
  margin-top: 78px;
  height: 325px;
  background-image: linear-gradient(rgba(0, 80, 36, 0.5), rgba(24, 20, 26, 0.5)),
    url("https://img.freepik.com/premium-photo/beautiful-star-cloud-black-background_758367-2452.jpg?w=1060");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding: 20px;
  backdrop-filter: blur(20px);
}
.loginImg {
  justify-content: center;
}
/* Add this style to your existing CSS file or style tag */
.imageAnimation {
  animation: moveUpDown 1s infinite alternate; /* You can adjust the duration and other properties */
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px); /* Adjust the vertical distance as needed */
  }
}
.aboutHeading {
  font-size: 22px;
  font-family: "proxima-nova", Arial, sans-serif;
}
.aboutDes {
  font-size: 40px;
  /* line-height: 1.3; */
  font-family: "Patua One", serif;
  font-weight: 500;
  text-align: start;
}
.aboutFirstSec {
  height: 600px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(16, 14, 14, 0.5)),
    url("https://ichef.bbci.co.uk/news/480/cpsprodpb/15DE8/production/_101067598_gettyimages-871604976.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.firstSecPara {
  font-family: "myriad-pro", "Source Sans Pro", "Helvetica neue", Helvetica,
    sans-serif;
  font-size: 20px;
  font-weight: 200;
  font-style: normal;
  color: #ffffff;
  text-align: center;
  line-height: 1.5em;
  padding: 0;
  margin: 0;
  clear: both;
  text-align: justify;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #ebeeef;
}

.wrap-login100 {
  width: 670px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.login100-form-title {
  width: 100%;
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  padding: 70px 15px 74px 15px;
}

.login100-form-title-1 {
  font-family: revert-layer;
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: center;
  font-weight: 700;
}

.login100-form-title::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-color: rgba(54, 84, 99, 0.7) !important; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(https://res.cloudinary.com/dbysrwz2b/image/upload/v1703743094/bg-01_e3hf5k.jpg);
}

.signupImage::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-color: rgba(54, 84, 99, 0.7) !important; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(https://img.freepik.com/free-photo/sign-up-form-button-graphic-concept_53876-101286.jpg?w=900&t=st=1703758848~exp=1703759448~hmac=f0f9255ce529416bae4b21112411840b78e024fec16e278b0c87496e84b021d0);
}

.login100-form {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 43px 88px 93px 190px;
}

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #b2b2b2;
}

.label-input100 {
  font-family: Poppins-Regular;
  font-size: 15px;
  color: #808080;
  line-height: 1.2;
  text-align: right;
  display: flex;
  position: absolute;
  top: 14px;
  left: -105px;
  width: 80px;
}
.input100 {
  font-family: Poppins-Regular;
  font-size: 15px;
  color: #555555;
  line-height: 1.2;
  border: none;
  outline: none;
  display: block;
  width: 100%;
  background: transparent;
  padding: 0 5px;
}

.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 1px;

  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  -moz-transition: all 0.6s;
  transition: all 0.6s;

  background: #57b846;
}

input.input100 {
  height: 45px;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.input-checkbox100 {
  display: none;
}

.label-checkbox100 {
  font-family: Poppins-Regular;
  font-size: 13px;
  color: #999999;
  line-height: 1.4;

  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.label-checkbox100::before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 13px;
  color: transparent;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background: #fff;
  border: 1px solid #e6e6e6;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-checkbox100:checked + .label-checkbox100::before {
  color: #57b846;
}

.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}

.login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 160px;
  height: 50px;
  background-color: #57b846;
  border-radius: 10px;

  font-family: Poppins-Regular;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background-color: #333333;
}

@media (max-width: 576px) {
  .login100-form {
    padding: 43px 15px 57px 117px;
  }
}

@media (max-width: 480px) {
  .login100-form {
    padding: 43px 15px 57px 15px;
  }

  .label-input100 {
    text-align: left;
    position: unset;
    top: unset;
    left: unset;
    width: 100%;
    padding: 0 5px;
  }
}

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 2px;
  pointer-events: none;

  font-family: Poppins-Medium;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

.fourthDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.review-categories-grid {
  display: grid;
  grid-template-columns: 1fr; /* Default to one column */
  gap: 16px; /* Adjust the gap as needed */

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
  }
}
.aboutheading {
  font-family: "Noticia Text", serif;
  font-weight: 500;
  color: inherit;
  line-height: 1.2;
  font-size: 25px;
}
.aboutheading1 {
  font-family: "Noticia Text", serif;
  font-weight: 500;
  color: inherit;
  line-height: 1.2;
  font-size: 20px;
}
.aboutPara {
  font-family: "Roboto", sans-serif;
  color: #3b3535c4;
  line-height: 1.8;
  font-size: 13px;
  text-align: justify;
}
.about-us-page1 {
  background: #f8f5f580;
}

@media (max-width: 768px) {
  .aboutPara {
    font-size: 12px;
  }
}

.aboutBtn {
  border: none;
  padding: 8px 14px;
  text-align: center;
  cursor: pointer;
  background-color: #57b846;
  border-radius: 25px;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutLast {
  font-size: 18px;
}
@media (max-width: 425px) {
  .aboutLast {
    font-size: 12px;
  }
  .aboutBtn {
    font-size: 10px;
  }
}

footer {
  width: 100%;
  background-color: #121619;
  color: #fff;
}

.top_header {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.top_header section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.top_header span {
  padding: 0 1rem;
}

.top_header .fa {
  color: #57b846;
  font-size: 35px;
}

footer .border-shape {
  width: 40%;
}

footer .border-shape::before {
  width: 100%;
  left: 120px;
}

footer .border-shape::after {
  width: 100%;
  right: 120px;
}

footer .bottom_content section {
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: Center;
}

.bottom_content a {
  margin: 0 20px;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.5s;
}

.bottom_content a:hover {
  color: rgba(255, 255, 255, 1);
}

.copyright {
  padding: 0.8em 0;
  background-color: #1e1e1e;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}

@media (max-width: 820px) {
  .top_header {
    padding: 1rem;
    display: block;
  }

  .top_header section {
    margin: 40px 0;
    align-items: left;
    justify-content: left;
  }
  footer .bottom_content section {
    padding: 1rem;
    display: block;
  }
  footer .bottom_content section a {
    padding: 1rem;
    font-size: 12px;
    margin: 0 5px;
    display: inline-block;
  }
}

.wrapper {
  margin: 8px 0;
}

.state {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1e-5;
  pointer-events: none;
}

.label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #394a56;
}

.text {
  margin-left: 16px;
  opacity: 0.6;
  transition: opacity 0.2s linear, transform 0.2s ease-out;
}

.indicator {
  position: relative;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6;
  overflow: hidden;
}

.indicator::before,
.indicator::after {
  content: "";
  position: absolute;
  top: 10%;
  left: 10%;
  height: 80%;
  width: 80%;
  border-radius: 50%;
}

.indicator::before {
  background-color: #57b846;
  box-shadow: -4px -2px 4px 0px #d1d9e6, 4px 2px 8px 0px #fff;
}

.indicator::after {
  background-color: #ecf0f3;
  box-shadow: -4px -2px 4px 0px #fff, 4px 2px 8px 0px #d1d9e6;
  transform: scale3d(1, 1, 1);
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}

.state:checked ~ .label .indicator::after {
  transform: scale3d(0.975, 0.975, 1) translate3d(0, 10%, 0);
  opacity: 0;
}

.state:focus ~ .label .text {
  transform: translate3d(8px, 0, 0);
  opacity: 1;
}

.label:hover .text {
  opacity: 1;
}

.pagination-btn {
  border: none;
  background-color: none !important ;
  padding: 8px 15px;
}
.averageContainer {
  background-color: #9acd32;
  padding: 8px 12px;
  font-size: 13px;
  border-radius: 6px;
  color: white !important;
  width: fit-content;
}

.table-responsive {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .table {
    width: 100%;
  }

  .table th,
  .table td {
    text-align: left;
  }
}

.pagination-btn {
  background-color: transparent;
  color: #115006;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: none !important;
  /* box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25); */
}

.contact-card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  padding: 20px;
  text-align: center;
  width: 100%;
}

.contact-card i {
  color: #57b846;
  font-size: 50px;
  margin-bottom: 10px;
}

.contact-card h3 {
  color: #333;
  font-size: 20px;
  margin-bottom: 10px;
}

.contact-card p {
  color: #666;
  font-size: 16px;
  margin-bottom: 10px;
}

.contact-form {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  padding: 20px;
}

.contact-form h2 {
  color: #375137;
  font-size: 22px;
  margin-bottom: 20px;
}

.contact-form input[type="text"],
.contact-form input[type="tel"],
.contact-form input[type="email"],
.contact-form textarea {
  background-color: #f2f2f2;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
}

.contact-form button[type="submit"] {
  background-color: #57b846;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
}

.contactImg {
  border-radius: 10px;
  height: 400px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.contact-us {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.contact-info {
  width: 100%;
  margin-right: 20px;
}

/* .contactform {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
} */

.contactLabel {
  display: block;
  font-family: Poppins-Regular;
  font-size: 15px;
  color: #808080;
  margin-bottom: 5px;
}

.contactip {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.contactBtn {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.contactBtn:hover {
  background-color: #3e8e41;
}

@media screen and (max-width: 768px) {
  .contact-info {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
